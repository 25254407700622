import React from "react";

const getCurrentYear = () => {
    return new Date().getFullYear();
  };

export default () => (
    <footer>
    <div className="bg-dark py-5">
        <div className="container">
            <div className="row">
                <div className="order-md-2 col-md-7">
                    <h2 className="h3 text-light">Contact Us</h2>
                    <form name="contact" netlify-honeypot="bot-field" data-netlify="true">
                        <input type="hidden" name="form-name" value="contact" aria-label="contact form" />
                        <div className="form-group d-none">
                            <label>Don’t fill this out if you're human:<input name="bot-field" aria-label="bot field" /></label>
                        </div>
                        <div className="form-group">
                            <div id="nameId" className="sr-only">Name</div>
                            <input type="text" className="form-control" name="Name" placeholder="Name" aria-label="Name" />
                        </div>
                        <div className="form-group">
                            <div id="emailId" className="sr-only">Email</div>
                            <input type="email" className="form-control" name="Email" placeholder="Email" aria-label="Email" />
                        </div>
                        <div className="form-group">
                            <div id="messageId" className="sr-only">Message</div>
                            <textarea type="text" className="form-control" name="Message" rows="5" placeholder="Message" aria-label="Message"></textarea>
                        </div>
                        <button type="submit" className="btn btn-light">Send</button>
                    </form>
                </div>
                <div className="order-md-1 col col-md-5 mt-md-0 mt-5 text-light">
                    <div className="mb-3 social">
                        <a href="https://www.facebook.com/OberlinCollege/" target="_blank" rel="noopener noreferrer" aria-label="Oberlin College Facebook"><span className="fab fa-facebook-square fa-2x mr-3"></span></a>
                        <a href="https://twitter.com/oberlincollege" target="_blank" rel="noopener noreferrer" aria-label="Oberlin College Twitter"><span className="fab fa-twitter fa-2x mr-3"></span></a>
                        <a href="https://www.instagram.com/oberlincollege/" target="_blank" rel="noopener noreferrer" aria-label="Oberlin College Instagram"><span className="fab fa-instagram fa-2x mr-3"></span></a>
                        <a href="https://www.flickr.com/photos/oberlin/albums" target="_blank" rel="noopener noreferrer" aria-label="Oberlin College Flickr"><span className="fab fa-flickr fa-2x"></span></a>
                    </div>
                    <p>{getCurrentYear()} &copy; Oberlin College</p>
                </div>
            </div>
        </div>
    </div>
    </footer>
)