import React from "react";
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import { graphql, StaticQuery } from 'gatsby';

export default () => (
    <StaticQuery
    query={graphql`
      query HeadingQuery {
        allAirtable(filter: {table: {eq: "data"}, data: {cover: {eq: true}, publish: {eq: true}}}, sort: {fields: data___published_date, order: DESC}) {
            nodes {
                recordId
                data {
                    story
                    slug
                }
            }
        }
      }
    `}
    render={data => (
    <nav className="navbar navbar-light bg-light fixed-top navbar-expand-lg">
        <a className="navbar-brand" href="/">
        <img src="/oc-logo.png" alt="Oberlin College" className="mr-4" />
        <span className="d-none d-md-inline-block">Oberlin Photo Stories</span>
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="toggle collapse navbar-collapse" id="navbarSupportedContent">
        <div className="dropdown mr-3">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            More Stories
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            {data.allAirtable.nodes.map(node => (
                <a className="dropdown-item" href={node.data.slug} key={node.recordId}>{node.data.story}</a>
            ))}
            </div>
        </div>
        {/* <span className="fas fa-sun fa-lg"></span> */}
        <ThemeToggler>
        {({ theme, toggleTheme }) => (
            <label>
            <input
                type="checkbox"
                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                checked={theme === 'dark'}
                aria-label="Switch between Dark and Light mode"
                className="checkbox"
            />{' '}
            {/* <span className="slider round"></span> */}
            <span className="toggle-switch"></span>
            </label>
        )}
        </ThemeToggler>
        {/* <span className="fas fa-moon fa-lg"></span> */}
        </div>
    </nav>
    )}
    />
)